/* -------------------------------------------------
      Datepicker cells
     ------------------------------------------------- */
/* -------------------------------------------------
      Datepicker
     ------------------------------------------------- */
/* -------------------------------------------------
      Navigation
     ------------------------------------------------- */
/* -------------------------------------------------
      Timepicker
     ------------------------------------------------- */
     .datepicker--cell-day.-other-month- {
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			&:hover {
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
			}
			&:empty {
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
			}
	}
	.datepicker--cell-year.-other-decade- {
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			&:hover {
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
					color: #c5c5c5;
			}
			&:empty {
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
					background: none;
					border: none;
			}
	}
	.-disabled-.-focus-.datepicker--cell-day.-other-month- {
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
	}
	.-disabled-.-focus-.datepicker--cell-year.-other-decade- {
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
			color: #dedede;
	}
	.-selected-.datepicker--cell-day.-other-month- {
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
	}
	.-selected-.datepicker--cell-year.-other-decade- {
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
			color: #fff;
			background: #a2ddf6;
	}
	.-selected-.-focus-.datepicker--cell-day.-other-month- {
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
	}
	.-selected-.-focus-.datepicker--cell-year.-other-decade- {
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
			background: #8ad5f4;
	}
	.-in-range-.datepicker--cell-day.-other-month- {
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
	}
	.-in-range-.datepicker--cell-year.-other-decade- {
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
			background-color: rgba(92, 196, 239, 0.1);
			color: #cccccc;
	}
	.-in-range-.-focus-.datepicker--cell-day.-other-month- {
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
	}
	.-in-range-.-focus-.datepicker--cell-year.-other-decade- {
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
			background-color: rgba(92, 196, 239, 0.2);
	}
	.datepicker--cells {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	}
	.datepicker--cell {
			border-radius: 4px;
			box-sizing: border-box;
			cursor: pointer;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			height: 32px;
			z-index: 1;
			&:empty {
					cursor: default;
			}
	}
	.datepicker--cell.-focus- {
			background: #f0f0f0;
	}
	.datepicker--cell.-current- {
			color: #4EB5E6;
	}
	.datepicker--cell.-current-.-focus- {
			color: #4a4a4a;
	}
	.datepicker--cell.-current-.-in-range- {
			color: #4EB5E6;
	}
	.datepicker--cell.-in-range- {
			background: rgba(92, 196, 239, 0.1);
			color: #4a4a4a;
			border-radius: 0;
	}
	.datepicker--cell.-in-range-.-focus- {
			background-color: rgba(92, 196, 239, 0.2);
	}
	.datepicker--cell.-disabled- {
			cursor: default;
			color: #aeaeae;
	}
	.datepicker--cell.-disabled-.-focus- {
			color: #aeaeae;
	}
	.datepicker--cell.-disabled-.-in-range- {
			color: #a1a1a1;
	}
	.datepicker--cell.-disabled-.-current-.-focus- {
			color: #aeaeae;
	}
	.datepicker--cell.-range-from- {
			border: 1px solid rgba(92, 196, 239, 0.5);
			background-color: rgba(92, 196, 239, 0.1);
			border-radius: 4px 0 0 4px;
	}
	.datepicker--cell.-range-to- {
			border: 1px solid rgba(92, 196, 239, 0.5);
			background-color: rgba(92, 196, 239, 0.1);
			border-radius: 0 4px 4px 0;
	}
	.datepicker--cell.-range-from-.-range-to- {
			border-radius: 4px;
	}
	.datepicker--cell.-selected- {
			color: #fff;
			border: none;
			background: #5cc4ef;
	}
	.datepicker--cell.-selected-.-current- {
			color: #fff;
			background: #5cc4ef;
	}
	.datepicker--cell.-selected-.-focus- {
			background: #45bced;
	}
	.datepicker--days-names {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin: 8px 0 3px;
	}
	.datepicker--day-name {
			color: #FF9A19;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			text-align: center;
			text-transform: uppercase;
			font-size: .8em;
	}
	.datepicker--cell-day {
			width: 14.28571%;
	}
	.datepicker--cells-months {
			height: 170px;
	}
	.datepicker--cell-month {
			width: 33.33%;
			height: 25%;
	}
	.datepicker--years {
			height: 170px;
	}
	.datepicker--cells-years {
			height: 170px;
	}
	.datepicker--cell-year {
			width: 25%;
			height: 33.33%;
	}
	.datepickers-container {
			position: absolute;
			left: 0;
			top: 0;
	}
	.datepicker {
			background: #fff;
			border: 1px solid #dbdbdb;
			box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			box-sizing: content-box;
			font-family: Tahoma, sans-serif;
			font-size: 14px;
			color: #4a4a4a;
			width: 250px;
			position: absolute;
			left: -100000px;
			opacity: 0;
			transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
			transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
			transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
			z-index: 100;
	}
	.datepicker.-from-top- {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
	}
	.datepicker.-from-right- {
			-webkit-transform: translateX(8px);
			transform: translateX(8px);
	}
	.datepicker.-from-bottom- {
			-webkit-transform: translateY(8px);
			transform: translateY(8px);
	}
	.datepicker.-from-left- {
			-webkit-transform: translateX(-8px);
			transform: translateX(-8px);
	}
	.datepicker.active {
			opacity: 1;
			-webkit-transform: translate(0);
			transform: translate(0);
			transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
			transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
			transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
	}
	.datepicker-inline {
			.datepicker {
					border-color: #d7d7d7;
					box-shadow: none;
					position: static;
					left: auto;
					right: auto;
					opacity: 1;
					-webkit-transform: none;
					transform: none;
			}
			.datepicker--pointer {
					display: none;
			}
	}
	.datepicker--content {
			box-sizing: content-box;
			padding: 4px;
	}
	.-only-timepicker- {
			.datepicker--content {
					display: none;
			}
			.datepicker--nav {
					display: none;
			}
			.datepicker--time {
					border-top: none;
			}
	}
	.datepicker--pointer {
			position: absolute;
			background: #fff;
			border-top: 1px solid #dbdbdb;
			border-right: 1px solid #dbdbdb;
			width: 10px;
			height: 10px;
			z-index: -1;
	}
	.-top-left- {
			.datepicker--pointer {
					top: calc(100% - 4px);
					-webkit-transform: rotate(135deg);
					transform: rotate(135deg);
					left: 10px;
			}
	}
	.-top-center- {
			.datepicker--pointer {
					top: calc(100% - 4px);
					-webkit-transform: rotate(135deg);
					transform: rotate(135deg);
					left: calc(50% - 10px / 2);
			}
	}
	.-top-right- {
			.datepicker--pointer {
					top: calc(100% - 4px);
					-webkit-transform: rotate(135deg);
					transform: rotate(135deg);
					right: 10px;
			}
	}
	.-right-top- {
			.datepicker--pointer {
					right: calc(100% - 4px);
					-webkit-transform: rotate(225deg);
					transform: rotate(225deg);
					top: 10px;
			}
	}
	.-right-center- {
			.datepicker--pointer {
					right: calc(100% - 4px);
					-webkit-transform: rotate(225deg);
					transform: rotate(225deg);
					top: calc(50% - 10px / 2);
			}
	}
	.-right-bottom- {
			.datepicker--pointer {
					right: calc(100% - 4px);
					-webkit-transform: rotate(225deg);
					transform: rotate(225deg);
					bottom: 10px;
			}
	}
	.-bottom-left- {
			.datepicker--pointer {
					bottom: calc(100% - 4px);
					-webkit-transform: rotate(315deg);
					transform: rotate(315deg);
					left: 10px;
			}
	}
	.-bottom-center- {
			.datepicker--pointer {
					bottom: calc(100% - 4px);
					-webkit-transform: rotate(315deg);
					transform: rotate(315deg);
					left: calc(50% - 10px / 2);
			}
	}
	.-bottom-right- {
			.datepicker--pointer {
					bottom: calc(100% - 4px);
					-webkit-transform: rotate(315deg);
					transform: rotate(315deg);
					right: 10px;
			}
	}
	.-left-top- {
			.datepicker--pointer {
					left: calc(100% - 4px);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					top: 10px;
			}
	}
	.-left-center- {
			.datepicker--pointer {
					left: calc(100% - 4px);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					top: calc(50% - 10px / 2);
			}
	}
	.-left-bottom- {
			.datepicker--pointer {
					left: calc(100% - 4px);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					bottom: 10px;
			}
	}
	.datepicker--body {
			display: none;
	}
	.datepicker--body.active {
			display: block;
	}
	.datepicker--nav {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;
			border-bottom: 1px solid #efefef;
			min-height: 32px;
			padding: 4px;
	}
	.datepicker--nav-title {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			cursor: pointer;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			border-radius: 4px;
			padding: 0 8px;
			i {
					font-style: normal;
					color: #9c9c9c;
					margin-left: 5px;
			}
			&:hover {
					background: #f0f0f0;
			}
	}
	.datepicker--nav-action {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			cursor: pointer;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			width: 32px;
			border-radius: 4px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			&:hover {
					background: #f0f0f0;
			}
			svg {
					width: 32px;
					height: 32px;
			}
			path {
					fill: none;
					stroke: #9c9c9c;
					stroke-width: 2px;
			}
	}
	.datepicker--nav-action.-disabled- {
			visibility: hidden;
	}
	.datepicker--nav-title.-disabled- {
			cursor: default;
			background: none;
	}
	.datepicker--buttons {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			padding: 4px;
			border-top: 1px solid #efefef;
	}
	.datepicker--button {
			color: #4EB5E6;
			cursor: pointer;
			border-radius: 4px;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			display: -webkit-inline-flex;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			height: 32px;
			&:hover {
					color: #4a4a4a;
					background: #f0f0f0;
			}
	}
	.datepicker--time {
			border-top: 1px solid #efefef;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 4px;
			position: relative;
	}
	.datepicker--time.-am-pm- {
			.datepicker--time-sliders {
					-webkit-flex: 0 1 138px;
					-ms-flex: 0 1 138px;
					flex: 0 1 138px;
					max-width: 138px;
			}
	}
	.datepicker--time-sliders {
			-webkit-flex: 0 1 153px;
			-ms-flex: 0 1 153px;
			flex: 0 1 153px;
			margin-right: 10px;
			max-width: 153px;
	}
	.datepicker--time-label {
			display: none;
			font-size: 12px;
	}
	.datepicker--time-current {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			font-size: 14px;
			text-align: center;
			margin: 0 0 0 10px;
	}
	.datepicker--time-current-colon {
			margin: 0 2px 3px;
			line-height: 1;
	}
	.datepicker--time-current-hours {
			line-height: 1;
			font-size: 19px;
			font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
			position: relative;
			z-index: 1;
			&:after {
					content: '';
					background: #f0f0f0;
					border-radius: 4px;
					position: absolute;
					left: -2px;
					top: -3px;
					right: -2px;
					bottom: -2px;
					z-index: -1;
					opacity: 0;
			}
	}
	.datepicker--time-current-minutes {
			line-height: 1;
			font-size: 19px;
			font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
			position: relative;
			z-index: 1;
			&:after {
					content: '';
					background: #f0f0f0;
					border-radius: 4px;
					position: absolute;
					left: -2px;
					top: -3px;
					right: -2px;
					bottom: -2px;
					z-index: -1;
					opacity: 0;
			}
	}
	.datepicker--time-current-hours.-focus- {
			&:after {
					opacity: 1;
			}
	}
	.datepicker--time-current-minutes.-focus- {
			&:after {
					opacity: 1;
			}
	}
	.datepicker--time-current-ampm {
			text-transform: uppercase;
			-webkit-align-self: flex-end;
			-ms-flex-item-align: end;
			align-self: flex-end;
			color: #9c9c9c;
			margin-left: 6px;
			font-size: 11px;
			margin-bottom: 1px;
	}
	.datepicker--time-row {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			font-size: 11px;
			height: 17px;
			background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
			&:first-child {
					margin-bottom: 4px;
			}
			input[type='range'] {
					background: none;
					cursor: pointer;
					-webkit-flex: 1;
					-ms-flex: 1;
					flex: 1;
					height: 100%;
					padding: 0;
					margin: 0;
					-webkit-appearance: none;
					&::-webkit-slider-thumb {
							-webkit-appearance: none;
							box-sizing: border-box;
							height: 12px;
							width: 12px;
							border-radius: 3px;
							border: 1px solid #dedede;
							background: #fff;
							cursor: pointer;
							transition: background .2s;
							margin-top: -6px;
					}
					&::-ms-tooltip {
							display: none;
					}
					&:hover {
							&::-webkit-slider-thumb {
									border-color: #b8b8b8;
							}
							&::-moz-range-thumb {
									border-color: #b8b8b8;
							}
							&::-ms-thumb {
									border-color: #b8b8b8;
							}
					}
					&:focus {
							outline: none;
							&::-webkit-slider-thumb {
									background: #5cc4ef;
									border-color: #5cc4ef;
							}
							&::-moz-range-thumb {
									background: #5cc4ef;
									border-color: #5cc4ef;
							}
							&::-ms-thumb {
									background: #5cc4ef;
									border-color: #5cc4ef;
							}
					}
					&::-moz-range-thumb {
							box-sizing: border-box;
							height: 12px;
							width: 12px;
							border-radius: 3px;
							border: 1px solid #dedede;
							background: #fff;
							cursor: pointer;
							transition: background .2s;
					}
					&::-ms-thumb {
							box-sizing: border-box;
							height: 12px;
							width: 12px;
							border-radius: 3px;
							border: 1px solid #dedede;
							background: #fff;
							cursor: pointer;
							transition: background .2s;
					}
					&::-webkit-slider-runnable-track {
							border: none;
							height: 1px;
							cursor: pointer;
							color: transparent;
							background: transparent;
					}
					&::-moz-range-track {
							border: none;
							height: 1px;
							cursor: pointer;
							color: transparent;
							background: transparent;
					}
					&::-ms-track {
							border: none;
							height: 1px;
							cursor: pointer;
							color: transparent;
							background: transparent;
					}
					&::-ms-fill-lower {
							background: transparent;
					}
					&::-ms-fill-upper {
							background: transparent;
					}
			}
			span {
					padding: 0 12px;
			}
	}
	.datepicker--time-icon {
			color: #9c9c9c;
			border: 1px solid;
			border-radius: 50%;
			font-size: 16px;
			position: relative;
			margin: 0 5px -1px 0;
			width: 1em;
			height: 1em;
			&:after {
					content: '';
					background: currentColor;
					position: absolute;
					height: .4em;
					width: 1px;
					left: calc(50% - 1px);
					top: calc(50% + 1px);
					-webkit-transform: translateY(-100%);
					transform: translateY(-100%);
			}
			&:before {
					content: '';
					background: currentColor;
					position: absolute;
					width: .4em;
					height: 1px;
					top: calc(50% + 1px);
					left: calc(50% - 1px);
			}
	}
	@media print {
			.datepickers-container {
					display: none;
			}
	}
	